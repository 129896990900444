import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'

const Policy = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="template" lang="ja" />
        <title>{`プライバシーポリシー | ${title}`}</title>
      </Helmet>
      <div className="common-header clearfix">
        <h1>プライバシーポリシー</h1>
        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /<li>プライバシーポリシー</li>
        </div>
      </div>

      <div className="main-wrapper">
        <div className="row">
          <div className="container">
            <h2>第一章. 個人情報保護方針</h2>
            <br />
            株式会社アイテック（以下「当社」）は、当社事業である「駐車場設備機器の製造・販売・設置ならびに時間貸駐車場の運営・管理・集金代行のサービス」を提供する事業体として、社会に貢献する企業として個人情報の適切な取扱いについて模範を示すべき立場にあるとの理念の下、個人情報を適切に取り扱うことの重要性を認識し、個人情報保護方針を定め、役員及び従業員が一体となってこれを遵守し、もって個人情報の保護及び個人の権利利益の保護に万全を尽くしてまいります。
            <br />
            <br />
            １．個人情報を取得するに当たっては、その利用目的をできる限り特定し、その目的の達成に必要な限度において個人情報を取得致します。
            <br />
            ２．個人情報を、本人から直接、書面によって取得する場合には、弊社名、個人情報保護管理者名及び連絡先、利用目的等をお知らせした上で、必要な範囲で個人情報を取得致します。
            <br />
            ３．個人情報の利用は、本人が同意を与えた利用目的の範囲内で行います。また、目的外利用を行わないため、必要な対策を講じる手順を確立し、実施致します。
            <br />
            ４．保有する個人情報を適切な方法で管理し、本人の同意なしに第三者に開示・提供致しません。
            <br />
            ５．保有する個人情報を利用目的に応じた必要な範囲内において、正確、かつ、最新の状態で管理し、個人情報の漏えい、滅失もしくは毀損など又はそれらのおそれに対して、合理的な安全対策を講じ、予防並びに是正に努めます。
            <br />
            ６．個人情報の処理を外部へ委託する場合は、漏えいや第三者への提供を行わない等を契約により義務づけ、委託先に対する適切な管理を実施致します。
            <br />
            ７．保有する個人情報についての苦情・相談は、弊社の問合せ窓口に連絡頂くことにより、これに対応致します。
            <br />
            ８．個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守致します。
            <br />
            ９．個人情報保護マネジメントシステムを定め、これを定期的に見直し、継続的に改善致します。
            <br />
            <br />
            制定日：2009年4月1日
            <br />
            最終改正日：2022年11月16日
            <br />
            <br />
            株式会社アイテック
            <br />
            代表取締役社長　前川 卓志
            <br />
            <br />
            <b>【個人情報保護方針についてのお問合せ先】</b>
            <br />
            <table>
              <tbody>
                <tr>
                  <th>名称</th>
                  <td>株式会社アイテック</td>
                </tr>
                <tr>
                  <th>所在地</th>
                  <td>
                    〒112-0004 東京都文京区後楽2丁目1番3号 ＮＳ飯田橋ビル1階
                  </td>
                </tr>
                <tr>
                  <th>宛先</th>
                  <td>個人情報相談受付窓口</td>
                </tr>
                <tr>
                  <th className="multi">連絡先</th>
                  <td className="multi">
                    TEL：03-5802-6871　FAX：03-5802-6872
                    <br />
                    E-MAIL：pms@i-tech-corp.co.jp
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <br />
            <h2>第二章. 個人情報の取扱い</h2>
            <br />
            <b>【個人情報の定義について】</b>
            <br />
            １．「個人情報」
            <br />
            個人情報の保護に関する法律に定める個人情報をいいます。
            <br />
            ２．「個人情報等」
            <br />
            個人情報及び、お客様等の識別に係る情報、通信サービス上の行動履歴、その他お客様の本サービスの利用に関して生成又は蓄積される情報であって、当社が本ポリシーに基づき収集する情報をいいます。具体的には、氏名、性別、生年月日、職業、住所、連絡先（メールアドレス、電話番号等）、決済に関する情報（クレジットカード・銀行口座に関する情報、信用情報等）、リファラ、IPアドレス、サーバーへのアクセス情報、Cookieその他の識別子、位置情報等が含まれ得ますが、これらに限られません。
            <br />
            ３．「関係会社」
            <br />
            財務諸表等の用語、様式及び作成方法に関する規則第8条第8項に定める関係会社をいいます。
            <br />
            ４．「当社等」
            <br />
            当社並びに当社の親会社である株式会社PKSHA
            Technology及びその関係会社をいいます。
            <br />
            <br />
            <b>【 取扱う個人情報とその利用目的について】</b>
            <br />
            当社は、適法かつ公正な手段で個人情報を取得し、以下に示す「当社等が取扱う個人情報等」の範囲内で利用します。なお、「利用目的」の範囲に含まれていない利用目的が生じたときは、法令によって許容される場合を除き、ご本人へ利用目的について同意を得たうえで利用いたします。
            <br />
            <br />
            [表1]当社等が取扱う個人情報等
            <br />
            <table>
              <tbody>
                <tr>
                  <th className="multi">対象情報</th>
                  <td className="multi">利用目的</td>
                  <td className="multi">保有個人データの該当有無</td>
                </tr>
                <tr>
                  <th className="multi">
                    当社等が提供するサービスにおいて記録される個人情報
                  </th>
                  <td className="multi">
                    ・当社等の製品・サービスの提供、アフターサービス、メンテナンス、改善、当社等の製品・サービスの新規開発のため
                    <br />
                    ・製品の利用状況・満足度の調査のため
                  </td>
                  <td className="multi">
                    該当有
                    <br />※
                    ただし、当社が受託する駐車場運営管理業務において取得する駐車場利用者等の個人情報は、当社ではなく委託元企業様に権利があり、保有個人データには該当しません。
                  </td>
                </tr>
                <tr>
                  <th className="multi">
                    駐車場等に設置されているカメラによる録画データに含まれる個人情報
                  </th>
                  <td className="multi">
                    ・運用管理、セキュリティ、課金管理のため
                  </td>
                  <td className="multi">
                    該当有
                    <br />※
                    ただし、当社が受託する駐車場運営管理業務において取得する駐車場利用者等の個人情報は、当社ではなく委託元企業様に権利があり、保有個人データには該当しません。
                  </td>
                </tr>
                <tr>
                  <th className="multi">
                    当社等へのお問い合わせに関する個人情報
                  </th>
                  <td className="multi">
                    ・お問い合わせに対するご案内のため
                    <br />
                    ・アフターサービス、改善、品質向上のため
                  </td>
                  <td className="multi">該当有</td>
                </tr>
                <tr>
                  <th className="multi">お取引先様に関する個人情報</th>
                  <td className="multi">
                    ・お取引先様管理のため
                    <br />
                    ・当社等の製品・及びサービスに関する情報提供、ご提案のため（電子メールを送信する方法によるものを含む）
                  </td>
                  <td className="multi">該当有</td>
                </tr>
                <tr>
                  <th className="multi">
                    当社等のウェブサイト及びその他ウェブサイトにおける閲覧情報
                  </th>
                  <td className="multi">
                    ・お客様の趣味嗜好の分析のため
                    <br />
                    ・分析結果に基づく広告配信の最適化のため
                  </td>
                  <td className="multi">
                    一部該当有
                    <br />※ Google LLC.のGoogle
                    Analyticsを利用して収集した情報には個人情報は含まれません。
                  </td>
                </tr>
                <tr>
                  <th className="multi">
                    役員・従業員・退職者に関する個人情報
                  </th>
                  <td className="multi">・契約、雇用及び人事管理のため</td>
                  <td className="multi">該当有</td>
                </tr>
                <tr>
                  <th className="multi">採用応募者の方の個人情報</th>
                  <td className="multi">・選考および採否に関する連絡のため</td>
                  <td className="multi">該当有</td>
                </tr>
                <tr>
                  <th className="multi">苦情及び相談に関する個人情報</th>
                  <td className="multi">・苦情及び相談への回答のため</td>
                  <td className="multi">該当有</td>
                </tr>
                <tr>
                  <th className="multi">
                    当社サービスのクレジットカード決済においてお客様から取得するクレジットカード情報
                  </th>
                  <td className="multi">
                    ・お客様に提供するサービスの代金を決済するため
                  </td>
                  <td className="multi">
                    該当無
                    <br />
                    ※
                    クレジットカード決済時に決済代行会社が提供する画面が呼び出され決済処理が行われるため、当社では情報を取得致しません。
                    <br />
                    ※
                    クレジットカード情報取り扱いの詳細については以下のとおりとします。
                    <br />
                    ・情報の取得者名：SBペイメントサービス株式会社
                    <br />
                    ・情報の提供先：お客様が決済時にご利用いただいたクレジットカード会社
                    <br />
                    ・保存期間：サービス代金決済時より7年間
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <b>【共同利用について】</b>
            <br />
            １．共同利用する個人情報の項目と利用目的
            <br />
            当社は、従業員の個人情報を、その利用目的に基づき共同利用いたします。
            <br />
            ２．共同利用する者の範囲
            <br />
            当社等（当社を除き、以下に定める会社に限る。）
            <br />
            ・株式会社PKSHA Technology
            <br />
            ・株式会社PKSHA Workplace
            <br />
            ・株式会社PKSHA Communication
            <br />
            ・株式会社PKSHA xOps
            <br />
            ・合同会社PKSHA Technology Capital
            <br />
            ・株式会社AlgoNaut
            <br />
            ・株式会社Sapeet
            <br />
            ・株式会社PKSHA Associates
            <br />
            ３．共同利用についての責任者
            <br />
            株式会社アイテック（東京都文京区後楽2-1-3
            NS飯田橋ビル　代表取締役　前川 卓志）
            <br />
            ４．取得方法
            <br />
            口頭(電話等)、問合せフォーム、製品・サービスが提供する入力フォーム、契約書その他書面(電子的・磁気的方式等によって作られた記録を含む)
            <br />
            <br />
            <b>【匿名加工情報に関する公表について】</b>
            <br />
            １．当社は、個人情報を特定の個人を識別できないように加工した情報（以下、「匿名加工情報」といいます。)の作成を行います。
            <br />
            ２．匿名加工情報の作成にあたり、加工を施す個人に関する情報の項目は以下のとおりです。
            <br />
            ・氏名
            <br />
            ・電話番号
            <br />
            ・個人識別符号
            <br />
            ・住所
            <br />
            ・生年月日
            <br />
            <br />
            <b>【Google Analyticsの利用について】</b>
            <br />
            １．当社Webサイトでは、Google LLC.のGoogle
            Analyticsを利用してお客様の当社等のWebサイト及びその他Webサイトにおける閲覧情報を収集しています。これに付随して生成されるテキストファイル「Cookie」を通じて分析を行うことがありますが、この際IPアドレスなどお客様情報の一部がGoogle
            LLC.に収集される可能性があります。Google
            Analyticsでデータが収集、処理される仕組みについては以下のページをご参照ください。
            <br />
            　「ユーザーがGoogleパートナーのサイトやアプリを使用する際のGoogleによるデータ使用」
            <br />　
            <a
              href="https://www.google.com/intl/ja/policies/privacy/partners/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.google.com/intl/ja/policies/privacy/partners/
            </a>
            <br />
            ２．Google
            Analyticsにより取得される情報には特定の個人を識別する情報は一切含まれません。それらの情報はGoogle
            LLC.により同社のプライバシーポリシーに基づいて管理されます。また、当社でもそれらの情報を特定の個人を識別するために使用することはありません。
            <br />
            　Google LLC. プライバシーポリシー
            <br />　
            <a
              href="https://www.google.com/intl/ja/policies/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.google.com/intl/ja/policies/privacy/
            </a>
            <br />
            ３．当社は、ファーストパーティCookie（Google
            AnalyticsのCookieなど）または他のファーストパーティIDと、サードパーティCookieまたは他のサードパーティIDを組み合わせ、お客様の年齢層、性別、興味や関心の分析に使用することがあります。
            <br />
            ４．お客様は、ブラウザのアドオン設定でGoogle
            Analyticsをオプトアウト（無効化）することにより、Google
            Analyticsによるお客様情報の収集を停止することが可能です。ただし、その場合当ウェブサイトの機能の一部が利用できない場合があります。
            <br />
            Google Analyticsをオプトアウトするには「Google Analytics
            オプトアウトアドオン」をインストールし、ブラウザのアドオン設定を変更してください。
            <br />
            　Google Analytics オプトアウトアドオン
            <br />　
            <a
              href="https://tools.google.com/dlpage/gaoptout/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://tools.google.com/dlpage/gaoptout/
            </a>
            <br />
            <br />
            <b>【個人情報の開示等に関する請求について】</b>
            <br />
            １．当社は、ご本人様から利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止（以下、“開示等”といいます。）を求められた場合は、個人情報（第三者提供記録を含みます。）について社内調査を行い、遅延なくご対応させていただきます。
            <br />
            　※
            ただし、ご請求内容によっては開示等に応じられない場合がございます。その場合はその理由に関してもご回答いたします。
            <br />
            ２．開示等の請求を行う場合、下記手順に沿って郵送にてご請求ください。請求内容について不備・不足事項等ある場合請求を受け付けることができない場合があります。なお、個人情報の開示または利用目的の通知の請求について、1回につき事務手数料として1,000円を郵便小為替にてご負担いただきます。郵便小為替については請求時に同封ください。
            <br />
            ３．「個人情報開示等請求書」により頂いた個人情報は、お問い合わせ対応に使用し、それ以外の使用は致しません。
            <br />
            <table>
              <tbody>
                <tr>
                  <th className="multi">本人が請求する場合</th>
                  <td className="multi">
                    開示等の請求を行う場合は、以下の書類をご準備いただき、配達記録郵便にてお送り下さい。
                    <br />
                    １．個人情報開示・訂正・削除依頼書
                    <br />
                    当社所定の
                    <a href="/assets/files/privacy.pdf" target="_blank">
                      「個人情報開示・訂正・削除依頼書」
                    </a>
                    を印刷の上、必要事項を記入してください。
                    <br />
                    ２．本人確認書類
                    <br />
                    運転免許証、パスポート、健康保険証などの、ご本人の氏名、現住所を確認できる書類のコピー。
                  </td>
                </tr>
                <tr>
                  <th className="multi">代理人が請求する場合</th>
                  <td className="multi">
                    開示等の請求を行う者が、法定代理人もしくは本人が委任した代理人である場合は、前項の書類に加えて、それぞれ下記の書類を同封下さい。
                    <br />
                    【法定代理人の場合】
                    <br />
                    １．法定代理権があることを確認するための書類
                    <br />
                    戸籍謄本、親権者の場合は扶養家族が記入された健康保険の被保険者証のコピーも可。
                    <br />
                    ２．法定代理人本人であることを確認するための書類
                    <br />
                    運転免許証、パスポート、健康保険証などの、代理人の氏名、現住所を確認できる書類のコピー。
                    <br />
                    【委任による代理人の場合】
                    <br />
                    １．委任状（個人情報開示依頼書添付書類）
                    <br />
                    <a href="/assets/files/privacy.pdf" target="_blank">
                      上記のファイル
                    </a>
                    をダウンロードして、必要事項を記入してください。
                    <br />
                    ２．ご本人の印鑑証明書（3ケ月以内に発行されたもの）
                    <br />
                    ３．委任による代理人本人であることを確認するための書類
                    <br />
                    運転免許証、パスポート、健康保険証などの、代理人の氏名、現住所を確認できる書類のコピー。
                  </td>
                </tr>
              </tbody>
            </table>
            　※開示等に関する詳細は、開示等請求窓口へお問い合わせください。
            <br />
            <br />
            <b>【個人情報に関する問い合わせ・開示等請求窓口】</b>
            <br />
            <table>
              <tbody>
                <tr>
                  <th>名称</th>
                  <td>株式会社アイテック</td>
                </tr>
                <tr>
                  <th>所在地</th>
                  <td>
                    〒112-0004 東京都文京区後楽2丁目1番3号 ＮＳ飯田橋ビル1階
                  </td>
                </tr>
                <tr>
                  <th>宛先</th>
                  <td>個人情報保護管理者 落合 正男</td>
                </tr>
                <tr>
                  <th className="multi">連絡先</th>
                  <td className="multi">
                    TEL：03-5802-6871　FAX：03-5802-6872
                    <br />
                    E-MAIL：pms@i-tech-corp.co.jp
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <b>【個人情報保護方針、個人情報の取扱いについての変更】</b>
            <br />
            当社は、当社が適当と判断する方法で告知することにより、個人情報保護方針、個人情報の取扱いについて変更できるものとします。当該告知は事前に行うこととしますが、緊急又はやむを得ない場合はこの限りではありません。
            <br />
            <br />
            <b>【個人情報の安全管理措置】</b>
            <br />
            当社は、個人情報について、漏洩、滅失又は毀損の防止等、その管理のために必要かつ適切な安全管理措置を講じます。また、個人情報を取り扱う従業員や委託先（再委託先等を含みます。）に対して、必要かつ適切な監督を行います。個人情報の安全管理措置の主な内容は以下のとおりです。
            <br />
            組織的安全管理措置
            <br />
            ・取得、利用、保存、提供、削除・廃棄等の段階ごとに、取扱方法、責任者・担当者及びその任務等について個人情報の取扱いに関する規程を策定。
            <br />
            ・個人情報の取扱状況について、定期的な監査を実施。
            <br />
            人的安全管理措置
            <br />
            ・従業者に個人情報の取扱いに関する定期的な研修を実施。
            <br />
            物理的安全管理措置
            <br />
            ・個人情報を取り扱う区域において、従業者の入退室管理及び持ち込む機器等の制限を行うとともに、権限を有しない者による個人情報の閲覧を防止する措置を実施。
            <br />
            ・個人情報を取り扱う機器、電子媒体及び書類等の盗難又は紛失等を防止するための措置を講じるとともに、社内の移動を含め、当該機器、電子媒体等を持ち運ぶ場合、機器の暗号化又はパスワード制御を実施。
            <br />
            技術的安全管理措置
            <br />
            ・アクセス制御を実施して、担当者及び取り扱う個人情報データベース等の範囲を限定。
            <br />
            ・個人情報を取り扱う情報システムを外部からの不正アクセス又は不正ソフトウェアから保護する仕組みを導入。
            <br />
            外的環境の把握
            <br />
            ・外部の事業者に個人情報を委託する場合は、委託先の評価を行い適切な安全管理措置を実施。
            <br />
            ・外国に個人情報を保管する場合は、外国における個人情報の保護に関する制度を把握した上で適切な安全管理措置を実施。
            <br />
            <br />
            <h2 id="inquiry">
              第三章. 問い合わせフォームにおける個人情報の取扱い
            </h2>
            <br />
            当社では問合せフォームから取得する個人情報の利用にあたり、下記の事項を明示します。
            <br />
            <br />
            １．事業者の名称
            <br />
            株式会社アイテック
            <br />
            ２．個人情報保護管理者の連絡先
            <br />
            <table>
              <tbody>
                <tr>
                  <th>名称</th>
                  <td>株式会社アイテック</td>
                </tr>
                <tr>
                  <th>所在地</th>
                  <td>
                    〒112-0004 東京都文京区後楽2丁目1番3号 ＮＳ飯田橋ビル1階
                  </td>
                </tr>
                <tr>
                  <th>宛先</th>
                  <td>個人情報保護管理者 落合 正男</td>
                </tr>
                <tr>
                  <th className="multi">連絡先</th>
                  <td className="multi">
                    TEL：03-5802-6871　FAX：03-5802-6872
                    <br />
                    E-MAIL：pms@i-tech-corp.co.jp
                  </td>
                </tr>
              </tbody>
            </table>
            ３．個人情報の利用目的
            <br />
            当社へのお問合せについてのご回答及びご連絡等、必要な対応のため。
            <br />
            ４．個人情報を第三者に提供する場合
            <br />
            当社は、法令等により許される場合を除き、本人の同意を得ずに当フォームにてご提供いただく個人情報を第三者に提供することはありません。
            <br />
            ５．個人情報の取扱いの委託を行う場合
            <br />
            当社は、個人情報の利用目的の達成のために必要がある場合は、外部委託先に取り扱いを委託することがあります。当該外部委託先については個人情報保護の観点から委託先としての適切性を評価した上で、選定しています。
            <br />
            ６．個人情報の開示等の請求について
            <br />
            当社は、保有個人情報について、利用目的の通知、開示、訂正、追加又は削除、利用の停止、消去又は第三者への提供の停止を求められた場合は、これに応じます。その場合の問合せ窓口は、上記個人情報保護管理者です。
            <br />
            ７．個人情報の提供の任意性
            <br />
            個人情報の提供は任意です。ただし、ご提供いただけない場合は、お問合せについて正確なご回答を行うことができない場合があります。
            <br />
            ８．自動的な情報の取得
            <br />
            当社では、当社が保有するWebサイトその他のWebサイトにおける閲覧情報をGoogle
            AnalyticsおよびHubSpotにより取得しております。
            <br />
            <br />
            制定日：2022年11月16日
            <br />
            最終改正日：2024年2月2日
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Policy
